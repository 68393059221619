import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MessageBox, Spacing, Text } from '@reservamos/elements';
import { useSelector } from 'react-redux';
import { shouldUseAdyen } from 'utils/adyen';
import YunoForm from '../YunoForm';
import CardForm from '../CardForm';
import PaypalForm from '../PaypalForm';
import KueskipayForm from '../KueskipayForm';
import AgencyForm from '../AgencyForm';
import StoreForm from '../StoreForm';
import ThirdPartyForm from '../ThirdPartyForm';
import AdyenForm from '../AdyenForm';
import PixForm from '../PixForm';
import FreePayForm from '../FreePayForm';
import ReservamosPayForm from '../ReservamosPayForm';
import SavedCards from '../SavedCards';
import ExchangePayForm from '../ExchangePayForm';
import MercadoPagoTDCForm from '../MercadoPagoTDCForm';
import availablePaymentEngines from '../../../constants/PaymentEngine';
import EfectyForm from '../EfectyForm/EfectyForm';
import TransferFormStrategy from './TransferFormStrategy';
import EvertecForm from '../EvertecForm';
import CieloTDCForm from '../CieloTDCForm';
import AplazoForm from '../AplazoForm';
/**
 * PaymentForm component renders the appropriate payment form based on the selected payment method.
 * It supports various payment methods like credit card, PayPal, agency pay, third party, etc.
 * The component also handles the display of error messages and saved cards when necessary.
 *
 * @param {Object} props - The props for the PaymentForm component.
 * @param {string} props.formType - The type of payment form to display.
 * @param {Function} props.submitCard - The callback function to submit credit card form.
 * @param {Function} props.submitCardFail - The callback function when credit card form submission fails.
 * @param {Function} props.submitTransfer - The callback function to submit transfer form.
 * @param {string} props.selectedOption - The currently selected payment option.
 * @param {boolean} props.cardErrorOccured - Flag indicating if there was an error with credit card payment.
 * @param {boolean} props.showSavedCards - Flag indicating if saved cards should be displayed.
 * @param {boolean} props.showAdyenForm - Flag indicating if the Adyen form should be displayed.
 * @param {string} props.paymentEngine - The payment engine being used.
 * @param {boolean} props.showCheckbox - Flag indicating if the checkbox should be displayed.
 * @param {number} props.purchaseTotal - The total amount of the purchase.
 * @param {boolean} props.isExchange - Flag indicating if the payment is for an exchange.
 * @returns {React.Component} The PaymentForm component.
 */
const PaymentForm = ({
  formType,
  submitCardFail,
  submitCard,
  submitTransfer,
  selectedOption,
  cardErrorOccured,
  showSavedCards,
  showAdyenForm,
  showCheckbox,
  paymentEngine,
  purchaseTotal,
  isExchange,
}) => {
  const selectedPaymentMethod = useSelector((state) => state.purchase.get('selectedPaymentMethod'));
  const { t } = useTranslation('purchase');
  const { enabled: mercadoPagoIsEnabled } = useSelector(
    (state) => state.whitelabelConfig.env.mercadoPago,
  );
  const { enabled: cieloIsEnabled } = useSelector((state) => state.whitelabelConfig.env.cielo);

  const shouldUseMercadoPago =
    mercadoPagoIsEnabled && paymentEngine === availablePaymentEngines.mercadoPago;
  const shouldUseCielo = cieloIsEnabled && paymentEngine === availablePaymentEngines.cielo;
  const shouldUseEvertec = selectedPaymentMethod?.engine === 'evertec';
  const shouldUseYuno = selectedPaymentMethod?.provider === 'yuno';

  if (shouldUseYuno) formType = 'yuno';

  let form;

  switch (formType) {
    case 'credit_card':
      form = (
        <Spacing vertical>
          <CardForm onSubmit={submitCard} onSubmitFail={submitCardFail} />
        </Spacing>
      );

      if (shouldUseAdyen(paymentEngine)) {
        form = (
          <div className="adyen-form">
            <Spacing vertical>
              {showSavedCards && <SavedCards />}
              <AdyenForm showAdyenForm={showAdyenForm} showCheckbox={showCheckbox} />
              {cardErrorOccured && (
                <MessageBox title={t('label.problems_paying')} borderColor="warning">
                  <Text size="S">{t('text.suggestions_to_payment_problems')}</Text>
                </MessageBox>
              )}
            </Spacing>
          </div>
        );
      }

      if (shouldUseMercadoPago) {
        form = <MercadoPagoTDCForm amount={purchaseTotal} />;
      }

      if (shouldUseEvertec) {
        form = <EvertecForm />;
      }

      if (shouldUseCielo) {
        form = <CieloTDCForm />;
      }

      break;
    case 'paypal':
      form = <PaypalForm />;
      break;
    case 'agency_pay':
      form = <AgencyForm />;
      break;
    case 'third_party':
      form = <ThirdPartyForm />;
      break;
    case 'reservamos_pay':
      form = <ReservamosPayForm />;
      break;
    case 'exchange':
      form = <ExchangePayForm />;
      break;
    case 'free_pay':
      form = <FreePayForm isExchange={isExchange} />;
      break;
    case 'transfer':
      form = (
        <TransferFormStrategy
          selectedPaymentMethod={selectedPaymentMethod}
          submitTransfer={submitTransfer}
        />
      );
      break;
    case 'store':
      form = <StoreForm />;
      break;
    case 'bnpl':
      if (selectedPaymentMethod?.provider === 'aplazo') {
        form = <AplazoForm />;
      } else {
        form = <KueskipayForm />;
      }
      break;
    case 'pix':
      form = <PixForm />;
      break;
    case 'efecty':
      form = <EfectyForm />;
      break;
    case 'yuno':
      form = <YunoForm engine={selectedPaymentMethod?.engine} />;
      break;
    default:
      form = <div />;
  }

  const displayForm = selectedOption === formType || formType === 'yuno';

  return (
    <div className="tab-content" id={formType}>
      {displayForm && form}
    </div>
  );
};

PaymentForm.propTypes = {
  formType: PropTypes.string.isRequired,
  selectedOption: PropTypes.string.isRequired,
  submitCard: PropTypes.func,
  submitCardFail: PropTypes.func,
  submitTransfer: PropTypes.func.isRequired,
  cardErrorOccured: PropTypes.bool,
  showSavedCards: PropTypes.bool,
  showAdyenForm: PropTypes.bool,
  paymentEngine: PropTypes.string,
  showCheckbox: PropTypes.bool,
  purchaseTotal: PropTypes.number.isRequired,
  isExchange: PropTypes.bool.isRequired,
};

PaymentForm.defaultProps = {
  cardErrorOccured: false,
  showSavedCards: false,
  showAdyenForm: false,
  showCheckbox: true,
};

export default PaymentForm;
