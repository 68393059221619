import React from 'react';
import { IconText } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import biweeklyPayment from '../../images/payment/aplazo-biweekly-payment.svg';
import noCreditCard from '../../images/payment/aplazo-no-credit.svg';
import programmedPayment from '../../images/payment/aplazo-programmed-payment.svg';
import './KueskipayIcons.scss';

const AplazoIcons = () => {
  const { t } = useTranslation('payment');
  const benefits = [
    {
      img: biweeklyPayment,
      text: t('aplazo_benefits.first'),
    },
    {
      img: noCreditCard,
      text: t('aplazo_benefits.second'),
    },
    {
      img: programmedPayment,
      text: t('aplazo_benefits.third'),
    },
  ];

  return (
    <div className="kueski-icons-grid">
      {benefits.map((data) => (
        <IconText key={data.img} iconSize="L" label={data.text} iconType={data.img} fontSize="S" />
      ))}
    </div>
  );
};

export default AplazoIcons;
